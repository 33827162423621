import styled from 'styled-components';

export const PupleHome = styled.div`
  display: inline-flex;
  width: 100vw;
  position: relative;
  right: -40px;
  padding-left: 2.2rem;
  margin-top: 3rem;

  p {
    text-transform: uppercase;
    color: #fe04f5;
    font-size: 46px;
    font-weight: 600;
    margin: 0;
    text-align: right;
    min-width: max-content;
  }

  svg {
    margin: auto 0;
    fill: #fe04f5 !important;
    margin-left: 15px;
    width: 50px;
    height: 32px;
    margin-left: 10px;
  }

  .line {
    width: -webkit-fill-available;
    border: 2px solid #fe04f5;
    height: 0;
    margin: auto;
  }

  @media (max-width: 768px) {
    position: inherit;
    display: block;
    width: -webkit-fill-available;
    padding-left: 0.8rem;

    svg {
      display: none;
    }

    p {
      min-width: fit-content !important;
      text-align: left;
      font-size: 20px;
    }
  }
`;
