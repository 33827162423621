import styled from 'styled-components';

export const StyledFooter = styled.footer`
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  #footer-bottom {
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-around;
    padding: 2rem;

    .column1 {
      h4 {
        margin-bottom: 0;
        color: #000;
      }
      h2 {
        color: #d76331;
        margin-top: 0;
        margin-bottom: 2rem;
      }

      a {
        display: inline-block;
        margin-bottom: 0.5rem;
        color: #141414;
      }
    }

    .column2 {
      h4 {
        margin-bottom: 1rem;
      }
      div {
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
      }

      img {
        width: 60px;
        height: 60px;
        border-radius: 20px;
        margin-right: 1rem;
        margin-bottom: 1rem;
        object-fit: cover;
        transition: all 0.3s;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .column3 {
      div {
        width: 80%;
        margin-top: 2rem;
        border: 1px solid #b6b6b6;
        border-radius: 30px;
        background-color: #fbfbfb;

        input {
          width: 100%;
          text-align: center;
          font-weight: normal;
          letter-spacing: 0.36px;
          height: 2.5rem;
          padding: 0.6rem;
          margin-bottom: 0;
          border: none;
          border-radius: 30px 30px 0 0;
          background-color: #fbfbfb;

          &:focus {
            border: none;
            outline: none;
          }

          &::placeholder {
            color: #b6b6b6;
          }
        }

        button {
          margin: 0;
          height: 2.5rem;
          border: none;
          border-top: 1px solid #b6b6b6;
          width: 100%;
          color: #d76331;
          font-size: 1rem;
          font-weight: bold;
          border-radius: 0 0 30px 30px;
          cursor: pointer;

          &:focus {
            border: none;
            border-top: 1px solid #b6b6b6;
            outline: none;
          }
        }
      }
    }

    .column4 {
      p {
        font-size: 0.8rem;
        font-weight: normal;
      }

      div {
        margin-top: 3rem;
      }

      img {
        margin-top: 0.5rem;
        width: 25px;
        height: 25px;
        margin-right: 1.5rem;
        object-fit: fill;
      }
    }
  }

  @media (max-width: 768px) {
    #footer-bottom {
      flex-wrap: wrap;

      .column1 {
        margin-bottom: 3rem;
      }

      .column2 {
        margin-bottom: 3rem;
      }

      .column3 {
        margin-bottom: 3rem;
      }
    }
  }
`;
