import styled from 'styled-components';

import close_icon from '../../assets/icons/close.png';

export const StyledModal = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0px;
  right: 0px;
  height: 100%;
  // width: 50%;
  background-color: rgba(81, 176, 241, 0.24);
  user-select: none;
  will-change: opacity;
  vertical-align: middle;
  padding: 1rem;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  &.animated--fade-in {
    animation-duration: 150ms;
  }

  @media (min-width: 768px) {
    padding: 2rem;
  }

  @media (min-width: 1024px) {
    padding: 3rem;
  }

  @media (min-width: 1600px) {
  }
`;

export const ModalContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  z-index: 1020;
  position: relative;
  /* background-color: #fff; */
  background-color: ${({ theme: { theme } }) =>
    theme === 'dark' ? '#0A0A0A' : '#fff'};
  box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, 0.2),
    1px 3px 15px 2px rgba(0, 0, 0, 0.2);
  user-select: text;
  will-change: top, left, margin, transform, opacity;
  border-radius: 20px;
  width: 90%;
  margin: auto;
  padding: 1rem;

  &.animated--grow-in {
    animation-duration: 250ms;
  }

  @media (min-width: 768px) {
    width: 70%;
    padding: 2rem;
  }

  @media (min-width: 1024px) {
    width: 40%;
  }

  @media (min-width: 1600px) {
    width: 35%;
  }
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  height: 28px;
  width: 28px;
  background-image: url(${close_icon});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  z-index: 1200;
`;
