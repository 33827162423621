import styled from 'styled-components';
import stroke from '../../assets/img/Stroke_Spray_Shapes_11.png';

export const StyledListicle = styled.div`
    width: 90%;
    margin: 0 auto;

    .btn-container {
      text-align: center;

      button {
        background: #000;
        color: #fff;
        border-radius: 5px;
        border: none;
        padding: 1rem 2rem;
        margin-bottom: 2rem;
        margin-left: 12rem;
        font-size: 25px;
      }
    }

    h3 {
      text-transform: uppercase;
      color: #224cc0;
      letter-spacing: 1rem;
      margin-left: 1rem;
      margin-bottom: 1rem;
    }
  }
  .about-content {
    overflow: hidden;
    position: relative;
    padding: 0 0 3rem 0;
    display: flex;
    flex-direction: column;

    &::after {
      position: absolute;
      content: 'LA';
      font-weight: bolder;
      font-size: 7rem;
      color: #f0f0f0;
      letter-spacing: 0px;
      right: 0;
      bottom: 0px;
      z-index: 0;
    }

    &::before {
      position: absolute;
      content: 'LA';
      font-weight: bolder;
      font-size: 7rem;
      color: #f0f0f0;
      letter-spacing: 0px;
      left: 0;
      top: 0;
      z-index: 0;
    }

    .content {
    }

    @media (min-width: 768px) {
      flex-direction: row;

      .content {
        width: 50%;
      }
    }
  }

  h1 {
    font-size: 2rem;
    line-height: 1.1;
    color: #141414;
    margin-top: 4rem;

    @media (min-width: 768px) {
      margin-top: unsetx;
      font-size: 3rem;
      line-height: 1.2;
    }
  }

  p {
    margin-bottom: 1rem;
    color: #141414;
    font-size: 13px;
    z-index: 1;
    position: relative;
  }

  p:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    p {
      margin-bottom: 2rem;
      font-size: 15px;
    }
  }
`;

export const ContentImage = styled.div`
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .image {
    position: relative;
    background-color: #ddd748;
    width: 85%;
    height: 17rem;

    img {
      position: absolute;
      top: -1rem;
      right: 1rem;
      width: 98%;
      height: 17rem;
      object-fit: cover;
    }

    .stroke {
      position: absolute;
      right: -3rem;
      bottom: 2rem;
      width: 100vw;
      height: 8rem;
      background-image: url(${stroke});
      background-size: cover;
    }

    p {
      position: absolute;
      bottom: -5rem;
      left: -1rem;
    }
  }

  @media (min-width: 768px) {
    width: 50%;
    height: 35rem;

    .image {
      width: 25rem;
      height: 30rem;

      img {
        top: -2rem;
        right: 2rem;
        width: 25rem;
        height: 30rem;
      }

      .stroke {
        right: -3rem;
        bottom: 4rem;
        width: 35rem;
        height: 10rem;
      }
    }
  }
`;
