import React from 'react';
import { StyledFooter } from './footer.styles';

// Assets
import Facebook from '../../assets/img/facebook.png';
import Instagram from '../../assets/img/instagram.png';
import Twiiter from '../../assets/img/twitter.png';
import LinkedIn from '../../assets/img/linkedin.png';
import Tumblr from '../../assets/img/t.png';
import ImageOne from '../../assets/img/Image 20.png';
import ImageTwo from '../../assets/img/Image 22.png';
import ImageThreee from '../../assets/img/Image 23.png';
import ImageFour from '../../assets/img/Image 24.png';

export default function Footer() {
  return (
    <StyledFooter>
      <section id="footer-bottom">
        <div className="column1">
          <h4>BHM 2021</h4>
          <h2>MARKETPLACE</h2>
          <ul>
            <li>
              <a href="#!">About Us</a>
            </li>
            <li>
              <a href="#!">Shop</a>
            </li>
            <li>
              <a href="#!">Blog</a>
            </li>
            <li>
              <a href="#!">Advertise</a>
            </li>
            <li>
              <a href="#!">Get Listed</a>
            </li>
          </ul>
        </div>
        <div className="column2">
          <h4>New Businesses</h4>
          <div>
            <img src={ImageOne} alt="Test" />
            <img src={ImageTwo} alt="Test" />
            <img src={ImageThreee} alt="Test" />
            <img src={ImageFour} alt="Test" />
          </div>
        </div>
        <div className="column3">
          <h4>Join Our Mailing List</h4>
          <div>
            <input type="text" placeholder="Type your e-mail" />
            <button type="submit">Join</button>
          </div>
        </div>
        <div className="column4">
          <h4>Contact</h4>
          <p>Headquarters: New York, NY</p>
          <p>info@officialblackwallstreet.com</p>

          <div>
            <h4>Follow Us</h4>
            <a href="#!">
              <img src={Facebook} alt="Facebook" />
            </a>
            <a href="#!">
              <img src={Twiiter} alt="Twitter" />
            </a>
            <a href="#!">
              <img src={Instagram} alt="Instagram" />
            </a>
            <a href="#!">
              <img src={LinkedIn} alt="LinkedIn" />
            </a>
            <a href="#!">
              <img src={Tumblr} alt="Tumblr" />
            </a>
          </div>
        </div>
      </section>
    </StyledFooter>
  );
}
