import styled from 'styled-components';

import bg from '../../assets/img/video-thumbnail.jpg';

export const Card = styled.div`
  margin: 4rem auto;
  margin-top: 4rem;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-size: 1rem;

      span {
        font-weight: 600;
        font-size: 1.2rem;
      }
    }

    .img-container {
      height: 300px;
      width: 100%;
      text-align: center;
      background-image: url(${bg});
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 3rem;
        height: 3rem;
        object-fit: contain;
        cursor: pointer;
        transition: all 0.4s ease-in-out;

        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }

  @media (min-width: 768px) {
    .content {
      flex-direction: row;

      .img-container {
        height: 350px;
        width: 350px;
      }

      p {
        margin-left: 3rem;
      }
    }
  }

  @media (min-width: 1024px) {
    .content {
      .img-container {
        height: 350px;
        width: 500px;
        text-align: center;
        margin-left: 1rem;
      }

      p {
        margin-left: 4rem;
      }
    }
  }
`;

export const TrailerContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
