import React from 'react';

import { HeroBg, StyledHero } from './hero.styled';

const Hero = () => {
  return (
    <StyledHero>
      <div className="top-title">
        <span>Black-Owned in LA</span> presented by{' '}
        <span>
          <i>Snowfall</i> on FX
        </span>
      </div>
      <a
        href="https://fxnow.fxnetworks.com/shows/snowfall/video/most-recent/vdka22357272"
        target="_blank"
        rel="noreferrer"
      >
        <HeroBg>
          <img src={require('../../assets/img/hero.jpg').default} alt="" />
        </HeroBg>
      </a>
    </StyledHero>
  );
};

export default Hero;
