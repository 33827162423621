import React, { useState } from 'react';
import Modal from '../custom-modal/modal.comp';
import { Card, TrailerContent } from './video-trailer.styled';

function VideoTrailer() {
  const [open, setOpen] = useState(false);

  return (
    <Card>
      <Modal
        open={open}
        close={() => setOpen(false)}
        closeIcon={true}
        className="trailer-modal"
      >
        <TrailerContent>
          <iframe
            title="LA trailer"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/SIN7evMNz5g"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </TrailerContent>
      </Modal>

      <div className="content">
        <div className="img-container">
          <img
            src={require(`../../assets/img/play.png`).default}
            alt="play button"
            onClick={() => setOpen(true)}
          />
        </div>

        <p>
          Watch The <br /> <span> SEASON FOUR </span> <br />
          Trailer Of <i>Snowfall</i>
        </p>
      </div>
    </Card>
  );
}

export default VideoTrailer;
