// Hero styles
import styled from 'styled-components';

export const StyledHero = styled.section`
  .top-title {
    background-color: #141414;
    color: #fff;
    transition: all 0.2s ease-in-out;
    text-align: center;
    line-height: 1.1;
    padding: 0.7rem 0;

    &:hover {
      background-color: #ff6e26;
    }

    a {
      display: block;
      width: 100%;
    }
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1600px) {
  }
`;

export const HeroBg = styled.div`
  img {
    width: 100%;
    height: auto;
  }

  @media (min-width: 768px) {
    height: 70vh;
  }

  @media (min-width: 1024px) {
    height: 100vh;
  }
`;
