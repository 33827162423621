import styled from 'styled-components';

export const StyledPost = styled.div`
  h1 {
    text-align: center;
  }

  h2 {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .post-img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
  }
`;

export const StyledRestaurant = styled.div`
  h3 {
    opacity: 0.7;
    margin-bottom: 1rem;
    font-weight: 500;
  }
  img {
    width: 80%;
    height: auto;
  }
`;
