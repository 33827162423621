import styled, { css } from 'styled-components';

export const StyledHeader = styled.header`
  z-index: 100;
  width: 100vw;
  height: 4.5rem;
  background-color: #f5f5f5;
  box-shadow: 0px 3px 3px #00000029;
  position: fixed;

  @media (min-width: 768px) {
    height: 5rem;
  }

  @media (min-width: 1024px) {
    height: 6rem;
  }
`;

export const Nav = styled.nav`
  z-index: 100;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.98;
  width: 75%;
  height: 100vh;
  background-color: #f5f5f5;
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 5rem;

  .menu-nav {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
    list-style-type: none;
    padding-right: 1rem;
    letter-spacing: 0.4px;
    color: #141414;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    transition: all 0.5s ease-in-out;

    &-item {
      margin-bottom: 1rem;
      transform: ${({ open }) =>
        open ? 'translateX(0)' : 'translateX(100vw)'};
      transition: all 0.5s ease-in-out;

      &.active > a {
        color: #ff6e26;
      }
    }

    &-link {
      display: inline-block;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      color: #141414;
      cursor: pointer;
      text-decoration: none;
      padding: 0.3rem 0.8rem;
      transition: all 0.5s ease-in-out;
      position: relative;

      &:hover {
        color: #ff6e26;
        transition: all 0.3s ease-in-out;

        &::after {
          content: '';
          position: absolute;
          top: 25%;
          left: 40%;
          width: 20px;
          height: 20px;
          background-color: #ff6e26;
          opacity: 0.3;
          border-radius: 50%;
          z-index: 99;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    width: 50%;
    .menu-nav {
      padding-right: 2rem;
    }
  }

  @media screen and (min-width: 1024px) {
    visibility: visible;
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    flex-direction: row;
    padding-left: 18rem;
    padding-top: unset;

    .menu-nav {
      display: block;
      transform: translateY(0);
      height: 100%;
      text-align: left;
      margin-top: 1.8rem;
      margin-right: 3rem;
      padding-right: unset;

      &-item {
        display: inline;
        padding-left: 1rem;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    padding-left: 22rem;

    .menu-nav {
      margin-right: 5rem;
    }
  }
`;

export const LogoWrapper = styled.a`
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 101;

  img {
    width: 200px;
    height: 40px;
    object-fit: cover;
  }

  @media screen and (min-width: 768px) {
    top: 1.5rem;
    left: 3.5rem;

    img {
      width: 250px;
      height: 50px;
      object-fit: cover;
    }
  }

  @media screen and (min-width: 1024px) {
    left: 4.5rem;
  }

  @media screen and (min-width: 1600px) {
    left: 7.5rem;
  }
`;

const openMenuStyles = css`
  transform: rotate(450deg);
  background: transparent;

  &::before {
    transform: rotate(45deg) translate(5px, 8px);
  }

  &::after {
    width: 28px;
    transform: rotate(-45deg) translate(3px, -7px);
  }
`;

const toggleMenu = ({ open }) => open && openMenuStyles;

export const MenuButton = styled.div`
  position: absolute;
  z-index: 101;
  right: 1rem;
  top: 1rem;
  height: 20px;
  width: 20px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;

  .menu-btn-burger {
    position: absolute;
    right: 0;
    top: 1.3rem;
    width: 20px;
    height: 3px;
    background: #ff6e26;
    transition: all 0.5s ease-in-out;
    border-radius: 2px;

    &::before {
      content: '';
      position: absolute;
      top: -8px;
      right: 0;
      width: 28px;
      height: 3px;
      background: #ff6e26;
      transition: all 0.5s ease-in-out;
      border-radius: 2px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 8px;
      right: 0;
      width: 10px;
      height: 3px;
      background: #ff6e26;
      transition: all 0.5s ease-in-out;
      border-radius: 2px;
    }

    ${toggleMenu}
  }

  @media screen and (min-width: 1024px) {
    visibility: hidden;
  }
`;
