import React from 'react';
import { PupleHome } from './pupleHeader.styled';
import { RightArrow } from '../../assets/svgs';

function PupleHeader() {
  return (
    <PupleHome>
      <p>Explore La businesses</p>
      <RightArrow />
      <div className="line"></div>
    </PupleHome>
  );
}

export default PupleHeader;
