import styled, { css } from 'styled-components';

import blue_spray from '../../assets/img/businesses/blue-spray.png';

const useSprayStyles = css`
  background-image: url(${blue_spray});
  background-size: cover;
`;

const sprayStyles = ({ sprayBackground }) => sprayBackground && useSprayStyles;

export const StyledBusiness = styled.div`
  padding-top: 3rem;
  padding-bottom: 2rem;

  ${sprayStyles}

  .business-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
  }

  @media (min-width: 768px) {
    padding-top: 2rem;
    padding-bottom: 2rem;

    .business-container {
      flex-direction: row;
    }
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1600px) {
  }
`;

export const StyledCard = styled.div`
  position: relative;

  img {
    width: 100%;
    height: 18rem;
    object-fit: cover;
  }

  h4 {
    text-align: center;
    color: #fe00f4;
  }

  @media (min-width: 768px) {
    img {
      height: 26rem;
      width: 15rem;
      padding: 0 1.5rem;
    }
  }

  @media (min-width: 1024px) {
    img {
      width: 18rem;
    }
  }

  @media (min-width: 1600px) {
    img {
      width: 20rem;
    }
  }
`;
