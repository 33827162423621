import styled from 'styled-components';
import bg from '../../assets/img/stroke.png';

export const StyledDiscover = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    background-image: url(${bg});
    background-size: 100% 100%;
    width: 18rem;
    height: 9rem;
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: 2rem;
      margin-top: -1rem;
    }
  }

  @media (min-width: 768px) {
    .content {
      width: 27rem;
      height: 9rem;

      h2 {
        font-size: 3rem;
        margin-left: 1rem;
      }
    }
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1600px) {
  }
`;
