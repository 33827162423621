import React from 'react';
import { ThemeProvider } from 'styled-components';
import ReactGA from 'react-ga';

import Hoc from './utils/Hoc';
import { defaultTheme } from './theme';
import GlobalStyles from './components/global.styled';
import Layout from './components/layout/layout.comp';
import UIProvider from './contexts/ui';
import BusinessGrid from './components/business-grid/business-grid.comp';
import Hero from './components/hero/hero.comp';
import About from './components/about/about.comp';

import { data1, data2 } from './mock-ups/business-data1';
import DiscoverMore from './components/discover-more/discover-more.comp';
import PupleHeader from './components/pupleHeader/pupleHeader.comp';
// import Card from './components/cardBusiness/cardBusiness.comp';
import VideoTrailer from './components/video-trailer/video-trailer.comp';

const TRACKING_ID = 'UA-149697699-2';
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <Hoc>
      <UIProvider>
        <ThemeProvider theme={defaultTheme}>
          <GlobalStyles />
          <Layout>
            <Hero />
            <About />
            <PupleHeader />
            {/* <Card
              img="imagecard1"
              text={
                <p>
                  By the numbers <br /> <span>over $1B</span> <br />
                  generated by <br /> Black-Owned <br /> business
                </p>
              }
            /> */}
            <BusinessGrid data={data1} />
            <VideoTrailer />
            <div>
              <h1 style={{ textAlign: 'center', marginBottom: '2rem' }}>
                <i>Snowfall</i> Neighborhood Car Wash Gallery
              </h1>
              <BusinessGrid data={data2} sprayBackground />
            </div>
            <DiscoverMore />
          </Layout>
        </ThemeProvider>
      </UIProvider>
    </Hoc>
  );
}

export default App;
