import React from 'react';
import { StyledDiscover } from './discover-more.styled';

export default function DiscoverMore() {
  return (
    <StyledDiscover>
      <div className="content">
        <h2>
          <a href="https://obws.com/" target="__blank">
            Discover More
          </a>
        </h2>
      </div>
    </StyledDiscover>
  );
}
