import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    * {
      box-sizing: border-box;
    }

    html {
      scroll-behavior: smooth;
    }

    body {
      margin: 0;
      padding: 0;
      min-height: 90vh;
      overflow-x: hidden;
      color: #141414;
      font-family: 'Poppins', sans-serif;

      &.open-modal {
          overflow: hidden !important;
      }
    }

    h1, h2, h3, h4, h5, h6 {
      margin-block-end: 0.1em;
      margin-block-start: 0.1em;
      margin-inline-start: 0em;
      margin-inline-end: 0.1em;
    }

    a {
      text-decoration: none;
    }

    .container {
      padding: 0 1.5rem;
    }

    .trailer-modal {
        background-color: transparent;
        box-shadow: none;
      }

    @media (min-width: 768px) {
      .container {
        padding: 0 3.5rem;
      }
    }

    @media (min-width: 1024px) {
      .container {
        padding: 0 4.5rem;
      }

      .trailer-modal {
        width: 50rem;
        min-height: 55%;
      }

      .post-modal {
        width: 75%;
        min-height: 65%;
      }
    }

    @media (min-width: 1600px) {
      .container {
        padding: 0 8.5rem;
      }

      /* .readmore-modal {
        width: 55rem;
        min-height: 55%;
      } */
    }

    /* copy this media queries for ease of adding responsiveness 🤪 */
    @media (min-width: 768px) {
    }

    @media (min-width: 1024px) {
    }

    @media (min-width: 1600px) {
    }
`;

export default GlobalStyles;
