import React from 'react';
import { useUI } from '../../contexts/ui';
import { useOutsideNavClick } from '../../utils/hooks/useOutsideNavClick';
import { StyledHeader, MenuButton, Nav, LogoWrapper } from './navbar.styled';

function Navbar() {
  const navRef = React.useRef(null);
  const { toggleNav, openNav } = useUI();
  const openMenu = () => toggleNav();
  const closeMenu = () => openNav && toggleNav();

  useOutsideNavClick(navRef, openNav, closeMenu);

  return (
    <StyledHeader ref={navRef}>
      <MenuButton open={openNav} onClick={openMenu}>
        <span className="menu-btn-burger" />
      </MenuButton>

      <LogoWrapper href="/" onClick={closeMenu}>
        <img src="/logo.png" alt="logo" width="60" />
      </LogoWrapper>

      <Nav open={openNav}>
        <ul className="menu-nav">
          <li className={`menu-nav-item`} onClick={closeMenu}>
            <a
              href="https://obws.com/"
              className="menu-nav-link"
              target="_blank"
              rel="noreferrer"
            >
              Home
            </a>
          </li>

          <li className={`menu-nav-item`} onClick={closeMenu}>
            <a
              href="https://obws.com/"
              className="menu-nav-link"
              target="_blank"
              rel="noreferrer"
            >
              Shop
            </a>
          </li>

          <li className={`menu-nav-item`} onClick={closeMenu}>
            <a
              href="https://obws.com/"
              className="menu-nav-link"
              target="_blank"
              rel="noreferrer"
            >
              Features
            </a>
          </li>

          <li className={`menu-nav-item`} onClick={closeMenu}>
            <a
              href="https://obws.com/"
              className="menu-nav-link"
              target="_blank"
              rel="noreferrer"
            >
              Advertise
            </a>
          </li>

          <li className="menu-nav-item" onClick={closeMenu}>
            <a
              href="https://obws.com/"
              className="menu-nav-link"
              target="_blank"
              rel="noreferrer"
            >
              Business
            </a>
          </li>
        </ul>

        <ul className="menu-nav">
          <li className={`menu-nav-item`} onClick={closeMenu}>
            <a
              href="https://obws.com/"
              className="menu-nav-link"
              target="_blank"
              rel="noreferrer"
            >
              Log In
            </a>
          </li>

          <li className="menu-nav-item" onClick={closeMenu}>
            <a
              href="https://obws.com/"
              className="menu-nav-link"
              target="_blank"
              rel="noreferrer"
            >
              Sign Up
            </a>
          </li>
        </ul>
      </Nav>
    </StyledHeader>
  );
}

export default Navbar;
