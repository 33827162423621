import React, { Fragment } from 'react';

import { StyledAbout } from './about.styled';
import Listcle from '../listicle/listicle.comp';
import aboutImg from '../../assets/img/post1.png';
import listcleImg2 from '../../assets/img/listicleImg2.jpg';
import { listcleOne, listcleTwo } from '../../mock-ups/post-details.data';

const About = () => {
  return (
    <StyledAbout>
      <Listcle
        number={2}
        listicle={listcleTwo}
        heading={
          <Fragment>
            The Cast and Crew of FX’s <em>Snowfall</em> Share Their Favorite
            Black-Owned LA Businesses
          </Fragment>
        }
        listcleImg={listcleImg2}
        content={`The cast and crew of FX’s Snowfall are many things. Co-created by the late John Singleton, the youngest person to be nominated for an Academy Award™ for Best Director, the show has no shortage of talent. In fact, the series features award-winning actors, celebrated producers, and Grammy® award-winning writers. But the Snowfall cast and crew members aren’t just talented entertainers, they’re also loyal patrons of a variety of LA Black-owned businesses. Here is a list of some of their favorites
        `}
        footer={
          <Fragment>
            Los Angeles is a city that’s teeming with Black people and Black
            culture. One of the best ways to immerse oneself can often be
            through the businesses owned by Black locals. FX’s drama series
            <em> Snowfall</em> is an interesting and exciting way to discover
            1980s Los Angeles, and through these businesses, you can continue to
            explore the city as it stands today. <br /> <br /> Be sure to watch
            Season 4 of <em>Snowfall</em> on FX, Wednesdays at 10pm, also now
            streaming on FX on Hulu.
          </Fragment>
        }
      />
      <Listcle
        number={1}
        listicle={listcleOne}
        listcleImg={aboutImg}
        heading="10 Best Black-Owned Spots for Food, Retail and More in LA"
        content="Snowfall’s Franklin Saint said it best, “You got three things in your life when you’re born: what your mama tells you, what the streets teach you, and what the future holds”. And while I don’t know anyone who can control what their mama says, we still have our hands on the steering wheel of our future. No one commands the car more than entrepreneurs, and at Official Black Wall Street, we love jumping in the passenger seat to go along for the ride. On this journey, we celebrate the city of FX’s Snowfall, as we ride through Los Angeles to stop at 10 Black-owned businesses at the heart of the city"
        footer=""
      />
    </StyledAbout>
  );
};

export default About;
