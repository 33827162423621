import React from 'react';
import { StyledBusiness, StyledCard } from './business-grid.styled';

export default function BusinessGrid({ data, sprayBackground }) {
  return (
    <StyledBusiness className="container" sprayBackground={sprayBackground}>
      <div className="business-container">
        {data &&
          data.map(({ img, category, link }, i) => (
            <StyledCard as="a" key={i} href={link} target="_blank">
              <img src={img} alt="" />
              <h4>{category}</h4>
            </StyledCard>
          ))}
      </div>
    </StyledBusiness>
  );
}
