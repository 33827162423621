import React, { Fragment } from 'react';
import ReactMarkdown from 'react-markdown';

import Modal from '../custom-modal/modal.comp';
import { StyledPost, StyledRestaurant } from './post-details-modal.styled';

export const Restaurant = ({ name, paragraph, img }) => {
  return (
    <StyledRestaurant>
      <h2
        className="h3"
        style={{
          color:
            (name === 'Angela Lewis' ||
              name === 'Walter Mosley' ||
              name === 'Kevin Carroll') &&
            '#d76331',
        }}
      >
        {name}
      </h2>
      <img src={img} alt="" style={{ width: '60%' }} />
      <ReactMarkdown>{paragraph}</ReactMarkdown>
    </StyledRestaurant>
  );
};

export default function PostDetailsModal({
  heading,
  intro,
  open,
  setOpen,
  headerImg,
  listicle,
  footer,
  number,
}) {
  return (
    <Modal
      open={open}
      close={() => setOpen(false)}
      closeIcon={true}
      className="post-modal"
    >
      <StyledPost>
        <h1 className="h1">{heading}</h1>
        <img src={headerImg} alt="" className="post-img" />
        {/* <p>{intro}</p> */}
        {number === 1 && (
          <div>
            <p>
              <em>Snowfall’s</em> Franklin Saint said it best, “You got three
              things in your life when you’re born: what your mama tells you,
              what the streets teach you, and what the future holds”. And while
              I don’t know anyone who can control what their mama says, we still
              have our hands on the steering wheel of our future. No one
              commands the car more than entrepreneurs, and at Official Black
              Wall Street, we love jumping in the passenger seat to go along for
              the ride. On this journey, we celebrate the city of FX’s{' '}
              <em>Snowfall</em>, as we ride through Los Angeles to stop at 10
              Black-owned businesses at the heart of the city
            </p>
            <p>
              The inimitable lifestyle and culture of LA are shaped by the
              businesses that their locals love. The heart of a city lies in the
              relationships between us and our favorite spots around the
              neighborhood. And something new is coming to Los Angeles.
            </p>
            <p>
              Grab tickets on{' '}
              <a
                href="https://www.eventbrite.com/e/snowfall-neighborhood-car-wash-registration-140228115067"
                target="__blank"
              >
                Eventbrite
              </a>{' '}
              for February 19th through February 21st. Be sure to watch the
              season 4 premiere of <i>Snowfall</i> on FX on February 24 at 10pm
              and next day on FX on Hulu. FX’s <i>Snowfall</i> is bringing
              bumping beats and shining chrome to Los Angeles at the{' '}
              <i>Snowfall</i> Neighborhood Car Wash.
            </p>

            <p>
              DISCLAIMER: Inclusion of any business or individual on the curated
              list does not constitute or imply its endorsement, recommendation,
              or favoring by FX Networks LLC, its affiliates, directors, or
              employees.
            </p>
          </div>
        )}

        {number === 2 && (
          <Fragment>
            <p>
              The cast and crew of FX’s <em>Snowfall</em> are many things.
              Co-created by the late John Singleton, the youngest person to be
              nominated for an Academy Award™ for Best Director, the show has no
              shortage of talent. In fact, the series features award-winning
              actors, celebrated producers, and Grammy® award-winning writers.
              But the <em>Snowfall</em> cast and crew members aren’t just
              talented entertainers, they’re also loyal patrons of a variety of
              LA Black-owned businesses. Here is a list of some of their
              favorites
            </p>
            <p>
              <em>
                FX and Snowfall do not make any claims or endorsements in
                relation to the mentioned businesses.
              </em>
            </p>
          </Fragment>
        )}

        {Object.keys(listicle).map((key, index) => {
          return (
            <div key={index}>
              {/* <h2>{key.toUpperCase()}</h2> */}
              {listicle[key].map(({ name, paragraph, img }, i) => (
                <Restaurant
                  name={name}
                  key={i}
                  paragraph={paragraph}
                  img={img}
                />
              ))}
            </div>
          );
        })}
        <p style={{ marginTop: '5rem' }}>{footer}</p>
      </StyledPost>
    </Modal>
  );
}
