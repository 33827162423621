import React, { Fragment, useState } from 'react';

import { ContentImage, StyledListicle } from './listicle.styled';
import PostDetailsModal from '../post-details-modal/post-details-modal.comp';

const Listcle = ({
  listcleImg,
  heading,
  content,
  listicle,
  footer,
  number,
}) => {
  // console.log(listicle);
  const [open, setOpen] = useState(false);
  // const strArr = content.split('.');
  return (
    <StyledListicle>
      <PostDetailsModal
        intro={content}
        heading={heading}
        headerImg={listcleImg}
        listicle={listicle}
        open={open}
        setOpen={setOpen}
        footer={footer}
        number={number}
      />
      <div>
        <div className="about-content">
          <ContentImage>
            <div className="image">
              <img src={listcleImg} alt="" />
              {/* <img src={stroke} alt="" /> */}
              <div className="stroke"></div>
            </div>
          </ContentImage>

          <div className="content">
            <h1>{heading}</h1>
            {number === 1 && (
              <Fragment>
                <p>
                  <em>Snowfall’s</em> Franklin Saint said it best, “You got
                  three things in your life when you’re born: what your mama
                  tells you, what the streets teach you, and what the future
                  holds”.
                </p>
                <p>
                  And while I don’t know anyone who can control what their mama
                  says, we still have our hands on the steering wheel of our
                  future.
                </p>
                <p>
                  No one commands the car more than entrepreneurs, and at
                  Official Black Wall Street, we love jumping in the passenger
                  seat to go along for the ride.
                </p>
                <p>
                  On this journey, we celebrate the city of FX’s{' '}
                  <em>Snowfall</em>, as we ride through Los Angeles to stop at
                  10 Black-owned businesses at the heart of the city.
                </p>
              </Fragment>
            )}
            {number === 2 && (
              <Fragment>
                <p>
                  The cast and crew of FX’s <em>Snowfall</em> are many things.
                </p>
                <p>
                  Co-created by the late John Singleton, the youngest person to
                  be nominated for an Academy Award™ for Best Director, the show
                  has no shortage of talent.
                </p>
                <p>
                  In fact, the series features award-winning actors, celebrated
                  producers, and Grammy® award-winning writers.
                </p>
                <p>
                  But the <em>Snowfall</em> cast and crew members aren’t just
                  talented entertainers, they’re also loyal patrons of a variety
                  of LA Black-owned businesses.
                </p>
              </Fragment>
            )}
            {/* {strArr.map((str, index) => {
              return <p key={index}>{str}.</p>;
            })} */}
          </div>
        </div>

        <div className="btn-container">
          <button onClick={() => setOpen(true)}>Read more</button>
        </div>
      </div>
    </StyledListicle>
  );
};

export default Listcle;
