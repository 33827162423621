export const data1 = [
  {
    img:
      'https://api.typeform.com/responses/files/138da790eb723b20a9f92434c68c14d2a0990137978041ebff8ce9cedef03c28/The_Rad_Black_Kids_web_69.jpg',
    category: 'Apparel',
    link:
      'https://obws.com/search/business/cities=15121&categories=51,65,113,180,184',
  },

  {
    img: require('../assets/img/businesses/1/b4.jpg').default,
    category: 'Food & Beverage',
    link:
      'https://obws.com/search/business/cities=15121&categories=17,23,64,75,151,159',
  },

  {
    img:
      'https://api.typeform.com/responses/files/fab423b45debd9aab84250c3b3acbeaad95ab80c3a5ab9858c04b928893f6de9/cetIMG_9878.jpg',
    category: 'Beauty & Grooming',
    link:
      'https://obws.com/search/business/cities=15121&categories=15,16,36,76,79,80,81,107,120',
  },

  {
    img:
      'https://api.typeform.com/responses/files/b9e6a56de7af6ce96d9589d31e285ed4627816d0860c6386de02c2026946a273/ISABELLE_BARROS_SMILING_HAPPY_WITH_FLORAL_touched_up.jpeg',
    category: 'Wellness',
    link:
      'https://obws.com/search/business/cities=15121&categories=22,61,78,124,133,134',
  },
];

export const data2 = [
  {
    img: require('../assets/img/businesses/2/b1.jpg').default,
    // category: 'Business name',
  },

  {
    img: require('../assets/img/businesses/2/b2.jpg').default,
    // category: 'Business name',
  },

  {
    img: require('../assets/img/businesses/2/b3.jpg').default,
    // category: 'Business name',
  },

  {
    img: require('../assets/img/businesses/2/b4.jpg').default,
    // category: 'Business name',
  },
];
