export const listcleOne = {
  restaurants: [
    {
      name: 'Ackee Bamboo Jamaican Cuisine',
      paragraph: `Owned by Delroy and Marlene Beckford, Ackee Bamboo Restaurant opened its door in Los Angeles, California in 2004. They’re serving their community with authentic Jamaican cuisine, and their friendly environment and mouth-watering menu have led to Ackee being a local favorite - the Yelp reviews don’t lie. You’ll catch regulars stopping in for oxtail, short ribs, jerk chicken, curry goat, and more. Visit their [website](https://ackeebamboo.com) if you’re trying to drool over more of their Caribbean specialties.`,
      img: require('../assets/img/post/1.jpg').default,
    },

    {
      name: 'Earle’s on Crenshaw',
      paragraph: `Los Angeles - meet New York. Brothers Cary and Duane Earle left Brooklyn to pursue different dreams in LA. While they each had their own dreams in aeronautical engineering and music, they eventually collaborated to pay an homage to a New York City staple - a hot dog cart. What started as a moving cart along Venice Beach would grow into [Earle’s on Crenshaw](https://www.earlesrestaurant.com/), a restaurant and full-scale catering company located in Crenshaw Square. Their menu features their classic loaded hot dogs, but we’re also eyeing their sides, fries, and desserts.`,
      img: require('../assets/img/post/4.jpg').default,
    },

    {
      name: 'The Serving Spoon',
      paragraph: `If you haven’t fallen in love with food, you haven’t been to [The Serving Spoon](https://theservingspoon.net/). With the idea that you can have “love at first bite,” Harold E. Sparks relocated from Hamilton, Ohio to Inglewood in 1982 to fulfill his dream of opening a soul food restaurant. “The Spoon,” as it is affectionately called, has served thousands of customers all looking for food to sink their teeth into, a place to feel like home, or someone else who can cook like mama. Family-own and operated, The Serving Spoon is now in the hands of Harold’s daughter and son-in-law.`,
      img: require('../assets/img/post/8.jpg').default,
    },

    {
      name: 'Simply Wholesome Health Food Store',
      paragraph: `Simply Wholesome Health Food Store is a combination of real ingredients, real vibes, and real community. Nestled in Los Angeles on West Slauson Avenue, their mission is to inspire a healthier lifestyle. From vitamins, herbs, and teas to get your body right to delicious food and smoothies to satisfy your appetite, [Simply Wholesome Health Food Store](https://www.simplywholesome.com/) has the entire experience.`,
      img: require('../assets/img/post/6.jpg').default,
    },
  ],
  coffeeShops: [
    {
      name: 'Bloom & Plume',
      paragraph: `While first come, first served is all good and well, Bloom & Plume is giving us #thirstcomethirstserved. The Los Angeles coffee shop is building its community one cup, one person, and one neighborhood at a time. Customers can get a dose of caffeine through cold brew, espresso, and cappuccino classics while dabbling into its pastries and parfaits. [Bloom & Plume](https://bloomandplumecoffee.com/) isn’t just a coffee shop. They’re a community-oriented brand looking to unite people through their love of coffee.`,
      img: require('../assets/img/post/3.jpg').default,
    },

    {
      name: 'Harun Coffee',
      paragraph: `Coffee meets culture. Based out of Leimert Park, [Harun Coffee](https://harunintl.com/) is serving up coffee, food, and apparel. Whether you’re on the hunt for delicious avocado toast, a hot cup of coffee, or a branded fitted cap, you can find them all here.`,
      img: require('../assets/img/post/5.jpg').default,
    },
  ],
  retails: [
    {
      name: 'Cross Colours',
      paragraph: `[Cross Colours](https://crosscolours.com/) is back. In the early 1990s, Carl Jones and TJ Walker founded an urban streetwear brand that went against the drab gangbanger workwear of the time, opened the inner-city up to positivity, and introduced baggy pants to the Yo! MTV Raps generation. Embraced by the rising hip hop movement, the brand's premise "Clothing Without Prejudice" and slogans like "Educate 2 Elevate" and "Stop D Violence" made it iconic. Cross Colours was draped across the backs of some of the biggest rappers, actors, and athletes, namely Snoop Dogg, Run DMC, TLC, Will Smith, and Magic Johnson. In light of the current resurgence of 90’s fashion and music, Jones and Walker are picking up exactly where they left off. With new collections on the horizon, Cross Colours continues its mission to produce “Clothing Without Prejudice” and promote education and peace in communities across America.`,
      img: require('../assets/img/post/7.jpg').default,
    },

    {
      name: 'The Rad Black Kids',
      paragraph: `[The Rad Black Kids](https://www.theradblackkids.com/) {TRBK} is an epicenter of culture through fashion, lifestyle, and the arts. Boy 1 is a 20-something year old style icon who is the intellectual function of everything style. Girl 1 is a 20-something year old style empress who is the laissez-faire function regarding style. She is a lover of colors, textures, prints, and patterns. This brother and sister duo have created TRBK as an avenue for radical expression through style, photography, lifestyle, culture, and the arts. Their brand features apparel, accessories, and footwear all geared towards allowing their customers to express themselves through fashion.`,
      img:
        'https://api.typeform.com/responses/files/138da790eb723b20a9f92434c68c14d2a0990137978041ebff8ce9cedef03c28/The_Rad_Black_Kids_web_69.jpg',
    },
  ],
  beauty: [
    {
      name: 'BeautyBeez',
      paragraph: `After traditional beauty supply stores left a lot to be desired, Brittney Ogike and her husband worked to create a brand that offers the best in natural hair care, melanin skincare, and brown girl beauty. This brand would become BEAUTYBEEZ. Created so women of color could shop, explore, and play in a world full of beauty, the brand is a one-stop-shop for all things hair, body, feminine hygiene, and more. An extension of the brand can be found at The Beauty Bar at [BEAUTYBEEZ](https://beautybeez.com/) where clients can enjoy hair, skin, and beauty services.`,
      img: require('../assets/img/post/2.jpg').default,
    },

    {
      name: 'Charli Beauty Bar',
      paragraph: `[CharLi Beauty Bar](http://www.charlibeautybar.com/) is a skincare studio in mid-city Los Angeles that specializes in wax, lash, and facial services. Owned by Charli Taylor, the brand sells sustainable products that directly support women in business in Ghana and Kenya. CharLi Beauty Bar also offers a natural product line geared towards helping women nourish their skin before and after waxing.
    \nThe inimitable lifestyle and culture of LA are shaped by the businesses that their locals love. The heart of a city lies in the relationships between us and our favorite spots around the neighborhood. And something new is coming to Los Angeles.
    `,
      img:
        'https://api.typeform.com/responses/files/fab423b45debd9aab84250c3b3acbeaad95ab80c3a5ab9858c04b928893f6de9/cetIMG_9878.jpg',
    },
  ],
};

export const listcleTwo = {
  talents: [
    {
      name: 'Walter Mosley',
      paragraph: '',
      img: require('../assets/img/post/10.JPG').default,
    },
    {
      name: 'AALBC',
      paragraph: `AALBC is the oldest, largest, and most popular online bookstore dedicated to African-American and Black literature from around the world.
      \n(347) 692-2522
       \n[https://aalbc.com/](https://aalbc.com/)`,
      img: require('../assets/img/post/11.jpg').default,
    },
    {
      name: 'Malik Books',
      img: require('../assets/img/post/12.jpg').default,
      paragraph: `3650 W Martin Luther King Jr. Blvd. Ste 245
\nLos Angeles, CA 90008
\n(323) 389-8040
\n[https://malikbooks.com/](https://malikbooks.com/)
`,
    },
    {
      name: 'Eso Won Books',
      paragraph: `4327 Degnan Blvd.
      \nLos Angeles, CA 90008
      \n(323) 290-1048
      \n[https://www.esowonbookstore.com/](https://www.esowonbookstore.com/)
`,
      img: require('../assets/img/post/13.jpg').default,
    },
    {
      name: 'Reparations Club',
      paragraph: `3054 S Victoria Ave.
      \nLos Angeles, CA 90016
      \n(323) 591-0012
      \n[https://rep.club/](https://rep.club/)
`,
      img: require('../assets/img/post/14.jpg').default,
    },
    {
      name: 'Angela Lewis',
      paragraph: 'Credit: Vince Trupsin',
      img: require('../assets/img/post/15.jpg').default,
    },
    {
      name: 'Kindred Space LA',
      paragraph: `2610 Southwest Dr.
      \nLos Angeles, CA 90043
      \n(323) 381-5511
      \n[https://www.kindredspacela.com/](https://www.kindredspacela.com/)`,
      img: require('../assets/img/post/16.jpg').default,
    },
    {
      name: 'Skin Pro Aesthetics',
      paragraph: `3007 Washington Blvd. Suite 110
      \nMarina del Rey, CA 90292
      \n(310) 306-4321
      \n[https://www.gogetwaxed.com/](https://www.gogetwaxed.com/),
      `,
      img: require('../assets/img/post/17.jpg').default,
    },
    {
      name: 'High & Tight',
      paragraph: `4759 W Washington Blvd.
      \nLos Angeles, CA 90016
      \n(323) 424-3456
      \n[http://www.highandtightla.com/](http://www.highandtightla.com/)
      `,
      img: require('../assets/img/post/18.jpg').default,
    },
    {
      name: 'Stuzo',
      paragraph: `4751 W Washington Blvd.
      \nLos Angeles, CA 90016
      \n(323) 917-5050
      \n[https://www.stuzoclothing.com/](https://www.stuzoclothing.com/)
      `,
      img: require('../assets/img/post/19.jpg').default,
    },
    {
      name: 'Kevin Carroll',
      paragraph: ``,
      img: require('../assets/img/post/20.jpeg').default,
    },
    {
      name: 'Curve Design',
      paragraph: `5379 Wilshire Blvd.
      \nLos Angeles, CA 90036
      \n(877) 942-8793
      \n[https://curvedesign.com/](https://curvedesign.com/)
      `,
      img: require('../assets/img/post/21.jpg').default,
    },
    {
      name: 'Bottom Dollar',
      paragraph: `Bottom Dollar is an automotive repair shop that specializes in auto and truck services as well as U-Haul rentals.
      \n602 S Main Street
      \nSalisbury, NC 28144
      \n(704) 310-5173
      `,
      img: require('../assets/img/post/22.jpg').default,
    },
  ],
};
