import styled from 'styled-components';

export const PageWrapper = styled.div`
  min-height: 100vh;
  padding-top: 4.5rem;

  @media (min-width: 768px) {
    padding-top: 6rem;
    width: unset;
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1600px) {
  }
`;
